<template>
  <div id="app">

    <b-table
      id="fullTable"
      :fields="fields"
      :items="displayRecords"
      small
      hover
      bordered
      dark
      responsive
    >
      <template #cell(serverName)="data">
        <span :style="getWarningCell(data.item.isServerWarning,'server')">{{data.item.serverName}}</span>
      </template>
      <template #cell(symbol)="data">
        {{ data.item.symbol  }}
      </template>
      <template #cell(price)="data">
        {{data.item.price}}
      </template>
      <template #cell(middle)="data">
        {{ data.item.middle  }}
      </template>
      <template #cell(diff)="data">
        {{data.item.diff}}
      </template>
      <template #cell(spread)="data">
        <span :style="getWarningCell(data.item.isSpreadWarning)">{{data.item.spread}}</span>
      </template>
      <template #cell(time)="data">
        <span :style="getWarningCell(data.item.isTimeWarning, 'time')">{{data.item.time}}</span>
      </template>
    </b-table>

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


export default {
  name: 'Price',
  props: {
    server: {
    type: String,
    default: '0'
    },
    symbol: {
    type: String,
    default: 'EURUSD'
    },
  },
  data() {
    return {
    priceRecords: [],
    symbolSettings: [],
    serverRecords: [],
    fields: [
      {
          key:"serverName",
          sortable: true,
          tdAttr: (value , key, item) => {
            return this.getWarningCell(item.isServerWarning);
          },
      },
      {
          key:"symbol",
          sortable: true,
      },
      {
          key:"price",
      },
      {
          key:"middle",
      },
      {
          key:"diff",
      },
      {
          key:"spread",
          tdAttr: (value , key, item) => {
            return this.getWarningCell(item.isSpreadWarning);
          },
      },
      {
          key:"time",
          tdAttr: (value , key, item) => {
            return this.getWarningCell(item.isTimeWarning, 'time');
          },
      },
    ],
    }
  },
  async mounted() {
    this.serverRecords = JSON.parse(localStorage.getItem('serverRecords'))
    this.symbolSettings = JSON.parse(localStorage.getItem('symbolSettings'))
  },
  setup () {
  },
  computed: {
    currentRecord() {
      return this.$store.getters.getPriceRecords.find(record => (record.serverId == this.server && record.symbol == this.symbol));
    },
    displayRecords() {
      let subSymbolSetting = this.symbolSettings.find(symbolSetting => (this.server == symbolSetting.serverId) && (this.symbol == symbolSetting.symbol));
      if(subSymbolSetting != undefined) {
        let result = [];
        subSymbolSetting.subSymbols.forEach(subSymbol => {
          let foundPrice = this.$store.getters.getPriceRecords.find(priceRecord => subSymbol.serverId == priceRecord.serverId && subSymbol.symbol == priceRecord.symbol);
          if(foundPrice != undefined) {
            foundPrice.diff = Number(this.currentRecord.middle - foundPrice.middle).toFixed(this.currentRecord.digits);
            result.push(foundPrice);
          }
          else{
            result.push({
              serverId : subSymbol.serverId,
              serverName : this.serverRecords.find(server => subSymbol.serverId ==server.id).serverName,
              symbol : subSymbol.symbol,
              price: "(no data)",
              priceStatus: "--",
              middle: "--",
              diff: "--",
              spread: "--",
              time: "--",
            });
          }
        });
        result.unshift(this.currentRecord);
        return result;
      }    
      else return [];
    }
  },
  methods: {
    getWarningCell(isWarning, type) {
      if(isWarning) {
        if(type == "time") {
          return { bgcolor: "yellow", color: "black"};
        }
        else return { bgcolor: "red", color: "white"};
      }
      else return {};
    }
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>